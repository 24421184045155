import {
  EXTRA_PROPERTY_CONFIGURATION_FULL_FRAGMENT,
} from '@/graphql/_Fragments/ExtraPropertyConfiguration/Full';

export const EXTRA_PROPERTY_FULL_FRAGMENT = `
  fragment extraPropertyFullFragment on ExtraProperty {
    uid
    schemaCode
    value
    key
    config {
      ...extraPropertyConfigurationFullFragment
    }
  }
  ${EXTRA_PROPERTY_CONFIGURATION_FULL_FRAGMENT}
`;
